import './index.scss';
import Loader from 'react-loaders';
import AnimatedLetters from '../AnimatedLetters';
import { useEffect, useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';


const Contact = () => {
  const [letterClass, setLetterClass] = useState('text-animate');
  const refForm = useRef(null);

  useEffect(() =>{
    document.title = "Contatti | T3"
  });

  useEffect(() => {
    setTimeout(() => {
      setLetterClass('text-animate-hover')
    },2000)
  }, []);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_n9exdu9', 'template_p0ep79r', refForm.current, 'e6l4h2cQ8wGpPjmQJ')
      .then( () => {
        alert('Messaggio inviato correttamente');
        window.location.reload(false)
      }, function(error) {
        alert('Messaggio NON inviato causa:' + error);
        console.log(error);
      });
  }

  return (
    <>
      <div className="container contact-page">
        <div className="text-zone">
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={['I',' ','n','o','s','t','r','i',' ','c','o','n','t','a','t','t','i']} 
              idx={15} 
            />
          </h1>
          <p>
            Grazie per aver visitato il nostro sito. Se avete avuto tempo di leggere la pagina <a href="/about">Chi Siamo</a> avrete capito che siamo un'azienda pronta ad aiutarvi in ogni problematica legata all'informatica.
            <br />
            Se avete bisogno di supporto o volete parlare con un professionista, non esitate a contattarci tramite email compilando il form sottostante.
            <br />
            Saremo lieti di offrirvi il nostro aiuto e di mettere la nostra esperienza al vostro servizio.
          </p>
          <div className='contact-form'>
            <form ref={refForm} onSubmit={sendEmail}>
              <ul>
                <li className='half'>
                  <input type="text" name="name" placeholder="Nome" required />
                </li>
                <li className='half'>
                  <input type="email" name="email" placeholder="Email" required />
                </li>
                <li>
                  <input type="text" name="subject" placeholder="Oggetto" required />
                </li>
                <li>
                  <textarea name="message" placeholder="Messaggio" required></textarea>
                </li>
                <li>
                  <input type="submit" className='flat-button' value="INVIA"/>
                </li>
              </ul>
            </form> 
            
          </div>
        </div>
        <div className="info-map">
          T3 Problem Solvers,
          <br />
          Italy,
          <br />
          Viale Europa 93, 36016 Thiene (VI)
          <br />
          <span>info@t3srl.net</span>
        </div>
        <div className="map-wrap">
          <MapContainer center={[45.71126, 11.49647]} zoom={13}>
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
            <Marker position={[45.71126, 11.49647]}>
              <Popup>Venite a trovarci!</Popup>
            </Marker>
          </MapContainer>
        </div>
      </div>
      <Loader type="pacman" />
    </>
  )
}


export default Contact;