import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import AnimatedLetters from '../AnimatedLetters';
import './index.scss';
import Loader from 'react-loaders';
import Logo from './Logo';

const Home = () => {

  const [letterClass, setLetterClass] = useState('text-animate');
  const firstArray = ['C', 'i', 'a', 'o', ','];
  const secondArray = ['N','o','i',' ','s','i','a','m','o',' ','i','l',' ','t','e','a','m',' ','d','i',' ','T','3','.'];
  const thirdArray = ['A','l',' ','t','u','o',' ','s','e','r','v','i','z','i','o','.'];
  
  useEffect(() =>{
    document.title = "Home | T3"
  });

  useEffect(() => {
    setTimeout(() => {
      setLetterClass('text-animate-hover')
    },4000)
  }, []);

  return (
    <>
    <div className="container home-page"> 
      <div className="text-zone">
        <h1>
          <AnimatedLetters letterClass={letterClass} strArray={firstArray} idx={10}/><br /> 
          <AnimatedLetters letterClass={letterClass} strArray={secondArray} idx={16}/><br /> 
          <AnimatedLetters letterClass={letterClass} strArray={thirdArray} idx={50}/><br /> 
        </h1>
        <h2>Sviluppo /  SitiWeb / Assistenza / Server</h2>
        <Link to="/contact" className="flat-button">CONTATTACI</Link>
      </div>
      <Logo />
    </div>
    <Loader type="pacman" />
    </>
  );
}

export default Home