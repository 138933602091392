import './index.scss';
import AnimatedLetters from '../AnimatedLetters';
import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from 'react-loaders';
import { faJava, faJsSquare, faPhp, faWindows } from '@fortawesome/free-brands-svg-icons';
import { faC, faMicrochip } from '@fortawesome/free-solid-svg-icons'

const About = () => {
  const [letterClass, setLetterClass] = useState('text-animate');

  useEffect(() =>{
    document.title = "Chi Siamo | T3"
  });

  useEffect(() => {
    setTimeout(() => {
      setLetterClass('text-animate-hover')
    },2000)
  }, []);

  return (
    <>
    <div className='container about-page'>
      <div className='text-zone'>
        <h1>
          <AnimatedLetters 
            letterClass={letterClass}
            strArray={['A','b','o','u','t',' ','u','s']}
            idx={15}
          />
        </h1>
        <p>
          Il nostro laboratorio si occupa di riparazioni di computer e notebook di ogni marca e modello. Siamo specializzati nella risoluzione di problemi hardware & software garantendo tempi di riparaione rapidi e di risultati efficienti.
          <br />
          Se il vostro dispositivo ha bisogno di un intervento, non esitate a contattarci per un preventivo gratuito.
          <br />
          Saremo Lieti di aiutarvi a risolvere ogni tipo di problematica e di far tornare il vostro dispositivo al massimo delle prestazioni
        </p>
        <p>
          Ci occupiamo anche di sviluppo di siti web ed applicazioni professionali.
          <br />
          Offriamo soluzioni personalizzate per le esigenze di ogni cliente, garantendo risultati di alta qualità e un design accattivante.
          <br />
          Qualsiasi sia il vostro business possiamo realizzare la soluzione perfetta per le vostre esigenze
        </p>
        <p>
          Siamo in grado di offrire anche consulenze per aiutare aziende e privati a risolvere problemi tecnici.
          <br />
          Siamo esperti nell'offrire consulenza e supporto per la risoluzione dei problemi tecnici e per l'implementazione di soluzioni efficienti ed innovative.
          <br />
          Inoltre per garantire un supporto sempre all'avanguardia siamo sempre impegnati nello studio e ricerca di tecnologie in grado di offrire i servizi migliori.
        </p>
      </div>

    <div className="stage-cube-cont">
      <div className="cubespinner">
        <div className="face1">
          <FontAwesomeIcon icon={faPhp} color="#232531"/>
        </div>
        <div className="face2">
          <FontAwesomeIcon icon={faJsSquare} color="#F0DB4F"/>
        </div>
        <div className="face3">
          <FontAwesomeIcon icon={faJava} color="#5382A1"/>
        </div>
        <div className="face4">
          <FontAwesomeIcon icon={faWindows} color="#737373"/>
        </div>
        <div className="face5">
          <FontAwesomeIcon icon={faC} color="#165CAA"/>
        </div>
        <div className="face6">
          <FontAwesomeIcon icon={faMicrochip} color="#077341"/>
        </div>
      </div>
    </div>

    </div>
    <Loader type="pacman" />
    </>
  )
}


export default About