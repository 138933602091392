import './index.scss'
import AnimatedLetters from '../AnimatedLetters';
import { useEffect, useState } from 'react';
import Loader from 'react-loaders';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faComputer, faShield} from '@fortawesome/free-solid-svg-icons'



const Remote = () => {
  const [letterClass, setLetterClass] = useState('text-animate');

  useEffect(() => {
    setTimeout(() => {
      setLetterClass('text-animate-hover')
    },2000)
  }, []);

  useEffect(() =>{
    document.title = "Assistenza Remota | T3"
  });

  return (
    <>
    <div className="container remote-page">
      <div className="text-zone">
        <h1>
          <AnimatedLetters
            letterClass={letterClass}
            strArray={['T','e','l','e','a','s','s','i','s','t','e','n','z','a']} 
            idx={15} 
          />
        </h1>
        <div className="remote-link">
          <p>Scarica il nostro programma di teleassistenza al seguente link:</p>
          <a href="https://www.t3srl.net/teleassistenza/CustomerPlugin.exe">
            <button >SCARICA ORA</button>
          </a>
        </div>
      </div>
      <div className="assistance-container">
          <div class="computer-image">
            <FontAwesomeIcon icon={faComputer} color="#fa0000"/>
            <FontAwesomeIcon className="connection-protection" icon={faShield} color="#fa0000"/>
          </div>
          <div class="computer-image">
            <FontAwesomeIcon icon={faComputer} color="#fa0000"/>  
            <FontAwesomeIcon className="connection-protection" icon={faShield} color="#fa0000"/>  
          </div>
          <div class="connection-data">
            <div>
              <FontAwesomeIcon icon={faCircle} color="#ffffff"/>  
            </div>
            <div>
              <FontAwesomeIcon icon={faCircle} color="#ffffff"/>
            </div>
            <div>
              <FontAwesomeIcon icon={faCircle} color="#ffffff"/>
            </div>
          </div>
        </div>
    </div>
    <Loader type="pacman" />
    </>
  )

}


export default Remote
