import { useEffect, useRef } from 'react'
import LogoS from '../../../assets/images/logosolid.png'
import './index.scss'

const Logo = () => {
  const bgRef = useRef(null)
  const solidLogoRef = useRef(null)

  const outlineTRef = useRef(null);
  const outline3Ref = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      outlineTRef.current.classList.add('draw');;
      outline3Ref.current.classList.add('draw');;
      // // outline3Ref.current.className = "draw";
      // document.querySelector('.first').classList.add('draw');
    },2000);
    setTimeout(() => {
      solidLogoRef.current.style.opacity = "1";
    }, 10000)
  }, []);


  return (
    <div className="logo-container" ref={bgRef}>
      <img className="solid-logo" ref={solidLogoRef} src={LogoS} alt="JavaScript,  Developer" />
      <svg id="Logo_shape" data-name="Logo shape" xmlns="http://www.w3.org/2000/svg" width="314" height="191" viewBox="0 0 314 191">
        <g className="svg-container">
          <path className="first" ref={outlineTRef} fill="none" d="M6.315,7.413V42.093H54.766V187.7H98.627V42.093h48.961V7.413H6.315Z"/>
          <path className="second" ref={outline3Ref} fill="none" d="M250.011,0.508q-29.835,0-46.41,15.3T185.75,59.669h42.586q0.765-10.453,6.12-16.7t14.535-6.248q8.417,0,13.388,4.973t4.973,14.153q0,21.17-28.816,20.655h-7.905v35.446h8.16q32.132,0,32.131,22.185,0,8.93-5.483,14.535t-14.918,5.61q-11.732,0-17.85-6.885t-6.63-18.87H183.455q1.53,31.365,19.763,46.666t49.343,15.3q19.126,0,32.769-6.758a48.277,48.277,0,0,0,20.782-18.87,53.458,53.458,0,0,0,7.141-27.668q0-32.382-34.936-43.606V92.309a39.263,39.263,0,0,0,22.568-13.515q9.048-10.71,9.053-28.306a47.771,47.771,0,0,0-7.013-25.628Q295.908,13.517,282.4,7.01t-32.386-6.5h0Z"/>
        </g>
      </svg>
    </div>
  )
}

export default Logo