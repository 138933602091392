import { Link, NavLink } from 'react-router-dom';
import './index.scss';
import logoT3 from '../../assets/images/logot3.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAmbulance, faEnvelope, faHome, faUser } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin, faSkype, faYoutube, faGithub } from '@fortawesome/free-brands-svg-icons';

  const Sidebard = () => (
      <div className="nav-bar">
        <Link className="logo" to="/">
          <img src={logoT3} alt="logo" />
        </Link>
        <nav>
          <NavLink exact="true" activeclassname="active" to="/">
            <FontAwesomeIcon icon={faHome} color="#4d4d4e" />
          </NavLink>
          <NavLink exact="true" activeclassname="active" className="about-link" to="/about">
            <FontAwesomeIcon icon={faUser} color="#4d4d4e" />
          </NavLink>
          <NavLink exact="true" activeclassname="active" className="contact-link" to="/contact">
            <FontAwesomeIcon icon={faEnvelope} color="#4d4d4e" />
          </NavLink>
          <NavLink exact="true" activeclassname="active" className="remote-link" to="/remote">
            <FontAwesomeIcon icon={faAmbulance} color="#4d4d4e" />
          </NavLink>
        </nav>
        <ul>
          <li>
            <a target="_blank" rel="noreferrer" href="https://www.t3srl.net">
              <FontAwesomeIcon icon={faLinkedin} color="#4d4d4e" />
            </a>
          </li>
          <li>
            <a target="_blank" rel="noreferrer" href="https://www.t3srl.net">
              <FontAwesomeIcon icon={faGithub} color="#4d4d4e" />
            </a>
          </li>
          <li>
            <a target="_blank" rel="noreferrer" href="https://www.t3srl.net">
              <FontAwesomeIcon icon={faYoutube} color="#4d4d4e" />
            </a>
          </li>
          <li>
            <a target="_blank" rel="noreferrer" href="https://www.t3srl.net">
              <FontAwesomeIcon icon={faSkype} color="#4d4d4e" />
            </a>
          </li>
        </ul>  
      </div>

    )

export default Sidebard
